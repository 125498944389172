import { Injectable,Inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { KeyCloakService } from '../keycloak.service';
import { LOCAL_STORAGE} from './local-storage.token';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements CanActivate {

  constructor(public ks: KeyCloakService, private router:Router, @Inject(LOCAL_STORAGE) private localStorage: Storage) { }
  // canActivate() {

  //   if (this.ks.user) {
  //     return true;
  //   }
  //   return false;

  // }

  userObj

  uname:string;

  setItem(key: string, value: any) {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  loadView(): boolean {
    if (this.userObj && this.userObj.user || this.userObj.admin) {
      return true
    } else {
      this.router.navigate(["/error"])
      return false
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    if (this.ks.user) {
      if (this.userObj) {
        return of(this.loadView())
      } else {
        return this.ks.getRole().pipe(map((response) => {
          this.userObj = response;
          return this.loadView()
        }))
      }
    } else {
      return of(false);
    }

  }

}
