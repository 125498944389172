<div class="container-fluid">
    <div class="row">
        <div class="col-md-4 offset-md-4">
            <div class="card mt-5 p-3">
                <form [formGroup]="userForm" (ngSubmit)="addUser()">
                    <div class="form-group mt-1">
                        <input formControlName="user" type="text" placeholder="username" class="form-control">
                    </div>
                    <div class="form-group mt-1">
                        <select formControlName="role" class="form-control">
                            <option value="">Select Role</option>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                    <div class="formgroup mt-1 text-center">
                        <button class="btn btn-primary" [disabled]="" type="submit">Add</button>
                    </div>
                </form>
                <div *ngIf="message" >
                    {{message}}
                </div>
            </div>
        </div>
    </div>
</div>