import { Component, OnInit } from '@angular/core';


import { HttpClient, HttpHeaders } from '@angular/common/http';
class wrapper {
  message: any
}

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.css']
})
export class OrganisationComponent implements OnInit {


  constructor(private http: HttpClient) { }

  domain_sel: boolean = false;
  domain:string;
  domain_content:any;
  process_sel:string;



  ngOnInit(): void {
  }


  domain_selected(arg: string) {

    this.domain_sel=false

    if (arg) {

      setTimeout(() => {

        this.domain_sel = true
      this.domain=arg
        
      }, 100);

      


    }





  }



  info_content(arg:string){
    this.process_sel=arg
    this.domain_content=""

    let query;

    if(arg == 'swot'){

      query=`Tell me about swot analysis for ${this.domain}.Please provide the content as an HTML-formatted document with the guide's text enclosed
       inside the body tags, 
      without including any other text or tags besides the body and its content.
      `

    }




   else{
    query=`Tell me about ${this.domain} with respect to its ${arg}.Please provide the content as an HTML-formatted 
    document with the guide's text enclosed inside the body tags, 
    without including any other text or tags besides the body and its content `

   }
      
      

 

    
//https://ai.onwingspan.com/nodeapi/

var options = {
  headers: new HttpHeaders({
     'Accept':'text/plain'
  }),
  'responseType': 'text' as 'json'
}

    this.http.post<wrapper>("https://ai.onwingspan.com/nodeapi/3001/mcq/org",{format:query},options)
    .subscribe(data => {

  
      console.log(data)

      // this.domain_content=data.message[0].text
      this.domain_content=data

      console.log(this.domain_content)

      

   

      

    })
  }


}
