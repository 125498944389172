import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';


let ACCESS_TOKEN_KEY = "synthaiz";

@Injectable({
    providedIn: "root"
})
export class KeyCloakService {

    user: any = null

    //url: string = "http://localhost:3001";
    url: string = "https://ai.onwingspan.com/nodeapi/3001";
    constructor(public http: HttpClient) { }

    getAccessTokenFromCode(options): Observable<any> {

        return this.http.post(this.url + "/auth/token/fetch", options)
    }


    getAccessEndPoints(options): Observable<any> {
        return this.http.post(this.url + "/auth", options)
    }


    getRole(): Observable<any> {
        return this.http.get(this.url + "/role/access")
    }

    addUser({ user, role }): Observable<any> {
        return this.http.post(this.url + "/role/access", { user, role })
    }





    isAuthenticated(authUrl, client) {
        let that = this;

        let redirectUri = `${window.location.origin}${window.location.pathname}`;
        let redirectPath = window.location.pathname;
        return new Promise((resolve, reject) => {
            const tokenInSession = localStorage.getItem(ACCESS_TOKEN_KEY);
            if (!tokenInSession) {
                const codeFromQueryParam = new URLSearchParams(window.location.search).get('code');
                // alert(codeFromQueryParam)
                if (window.location.hash.includes('code') || codeFromQueryParam !== null) {
                    const code = codeFromQueryParam || (window.location.hash.split('#')[1]
                        .split('&')
                        .filter(item => item.split('=')[0] === 'code')[0]
                        .split('=')[1]);

                    // Use this code and send it to backend to get the access token.
                    // After success go back to URL without hash.

                    this.getAccessTokenFromCode({
                        code,
                        redirectUri,
                        domain: window.origin
                    }).subscribe({
                        next(value) {
                            if (value) {
                                localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(value));
                                that.parseJwt(value.access_token)
                                resolve([value, redirectPath])
                            } else {
                                reject()
                            }
                        },
                        error(err) {
                            console.error(err, 'Unable to fetch the access token');
                            reject();
                        }
                    })

                } else {
                    this.initiateLogin(authUrl, client);
                }
            } else {
                // Token is there in the session
                if (!this.isTokenValid(tokenInSession)) {
                    localStorage.removeItem(ACCESS_TOKEN_KEY);
                    this.initiateLogin(authUrl, client);
                } else {
                    resolve([tokenInSession, redirectPath]);
                }
            }
        });
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload: any = decodeURIComponent(atob(base64).split('').map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        jsonPayload = JSON.parse(jsonPayload);
        let { family_name, given_name, wid, email } = jsonPayload;
        let user = {
            firstName: given_name,
            lastName: family_name, roles: [], token, userId: wid, email
        }
        this.user = user;
        return jsonPayload
    }

    isTokenValid(token) {
        const parsedTokenValue = this.parseJwt(JSON.parse(token).access_token);
        return (new Date(parsedTokenValue.exp * 1000).getTime() - new Date().getTime()) > 1
    }


    initiateLogin(authUrl: string, client: string) {
        const redirectUri = window.location.href;
        const clientId = client;
        const responseType = 'code';
        console.log(authUrl, redirectUri);
        // @ts-ignore
        window.location = `${authUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}`;
    }

}