import { Component, OnInit,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { LoginService } from '../login/login.service';


@Component({
  selector: 'app-zara',
  templateUrl: './zara.component.html',
  styleUrls: ['./zara.component.css']
})
export class ZaraComponent implements OnInit {
  @ViewChild('myIframe') myIframe: ElementRef;

  userName;

  constructor(public ls: LoginService) { }



onIframeLoad() {
  const iframeWindow = this.myIframe.nativeElement.contentWindow;
  iframeWindow.postMessage({ message: this.userName }, '*')
}

  ngOnInit(): void {
    this.userName=this.ls.uname
    console.log("in zara",this.userName)
    this.ls.setItem("uname",this.userName)
 
  }

  

}
