import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
class wrapper {
  text: any
}

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent implements OnInit {

  constructor(private http: HttpClient) { }

  domain_sel: boolean = false;
  domain: string;
  domain_content: any;
  process_sel: string;

  domains = [
    { name: "Communication, Media & Entertainment", img: "assets/communication.jpg" },
    { name: "CPG, Retail & Logistics", img: "assets/retail.jpg" },
    { name: "Energy, utilities & services", img: "assets/energy.jpg" },
    { name: "Financial Services", img: "assets/finance.jpg" },
    { name: "Healthcare, Insurance & Life Sciences", img: "assets/healthcare.jpg" },
    { name: "Manufacturing", img: "assets/manufacturing.jpg" },
    // {name:"Public Sector", img:""},
    // {name:"Technology, Media & Telecom", img:""},
    // {name:"Travel, Transportation & Hospitality", img:""},
  ]



  ngOnInit(): void {
  }


  domain_selected(arg: string) {

    this.domain_sel = false

    if (arg) {

      setTimeout(() => {

        this.domain_sel = true
        this.domain = arg

      }, 100);




    }





  }



  info_content(arg: string) {
    this.process_sel = arg
    this.domain_content = ""

    let query;

    if (arg == 'projects') {

      query = `Provide some information on top projects that are existing in ${this.domain} domain.The output should be in string format and it should not have anything else except content.
      Please provide the content as an HTML-formatted document with the guide's text enclosed inside the body tags, 
      without including any other text or tags besides the body and its content`

    }

    else if (arg == 'top players') {

      // query=`Provide some information on list of major enterprises that are existing in ${this.domain} domain.The output should be in string format and it should not have anything else except content.`
      query = `Provide some information on list of major enterprises that are existing in ${this.domain} domain.
      Please provide the content as an HTML-formatted document with the guide's text enclosed inside the body tags, 
      without including any other text or tags besides the body and its content and i do not want any header tags.`
    }

    else if (arg == 'mega trends') {

      console.log("in mega trends")

      query = `Provide some information on major trends in ${this.domain} domain.The output should be in string format and it should not have anything else except content.
      Please provide the content as an HTML-formatted document with the guide's text enclosed inside the body tags, 
      without including any other text or tags besides the body and its content`

    }

    else if (arg == 'case study') {

      query = `Provide some good case studies in ${this.domain} domain.The output should be in string format and it should not have anything else except content.
      Please provide the content as an HTML-formatted document with the guide's text enclosed inside the body tags, 
      without including any other text or tags besides the body and its content`

    }

    else if (arg == 'challenges') {

      query = `Provide some information on challenges faced in ${this.domain} domain.The output should be in string format and it should not have anything else except content.
      Please provide the content as an HTML-formatted document with the guide's text enclosed inside the body tags, 
      without including any other text or tags besides the body and its content`

    }

    else if (arg == 'culture') {

      query = `Provide some information on culture of  ${this.domain} domain.The output should be in string format and it should not have anything else except content.
      Please provide the content as an HTML-formatted document with the guide's text enclosed inside the body tags, 
      without including any other text or tags besides the body and its content`

    }

    else if (arg == 'evolution') {

      query = `Provide some information on evolution of  ${this.domain} domain.The output should be in string format and it should not have anything else except content.
      Please provide the content as an HTML-formatted document with the guide's text enclosed inside the body tags, 
      without including any other text or tags besides the body and its content`

    }

    else if (arg == 'future') {

      query = `Provide some information on what could be the future of ${this.domain} domain.The output should be in string format and it should not have anything else except content.
      Please provide the content as an HTML-formatted document with the guide's text enclosed inside the body tags, 
      without including any other text or tags besides the body and its content`

    }

    else if (arg == 'industry analysis') {

      query = `Provide some industry analysis of ${this.domain} domain.The output should be in string format and it should not have anything else except content.
      Please provide the content as an HTML-formatted document with the guide's text enclosed inside the body tags, 
      without including any other text or tags besides the body and its content`

    }



    else {
      console.log("in else part")
      query = `let me know about ${this.domain} domain with respect to ${arg}. The output should be in string format and it should not have anything else except content.
      Please provide the content as an HTML-formatted document with the guide's text enclosed inside the body tags, 
      without including any other text or tags besides the body and its content `

    }

    //http://localhost:3001/mcq/domain


    var options = {
      headers: new HttpHeaders({
        'Accept': 'text/plain'
      }),
      'responseType': 'text' as 'json'
    }

    this.http.post<wrapper>("https://ai.onwingspan.com/nodeapi/3001/mcq/domain", { format: query }, options)
      .subscribe(data => {

        console.log(data)
        // console.log(data.message[0].text)

        // this.domain_content=data.message[0].text

        this.domain_content = data









      })
  }

}
