import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router ,NavigationEnd } from '@angular/router';
import { KeyCloakService } from './keycloak.service';
import { LoginService } from './login/login.service';
import { record } from './record.js';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    public ks: KeyCloakService,
    public router: Router,
    public ls: LoginService,
    private http: HttpClient
  ) {}
  showChat = false;
botStyle=false;
  flag=false;

  changebot(){
    this.router.navigate(['/embed-bot'])
    this.botStyle=true
  }

  qType: string = 'socrative';
  userInput = '';
  triggerCount = 0;
  chatArray = [];
  ldArr = [];
  expand = false;
  timer = [];
  userName = '';
  count = 200;
  scrollInterval;
  ngOnInit() {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      // Call your property or method here
      if (event.url != '/embed-bot') {
        this.botStyle = false;
      }
     
    });



    let authUrl =
      'https://wingspan-dev.infosysapps.com/auth/realms/wingspan/protocol/openid-connect/auth';
    let client = 'synthaiz';
    //@ts-ignore
    this.ks
      .isAuthenticated(authUrl, client)
      //@ts-ignore
      .then(([tokenInSession, redirectPath]) => {
        this.router.navigate([redirectPath]);
      });
   
      //////////////////////////////////////chatbot///////////////////////////////////////////////
    let myToken = localStorage.getItem('synthaiz');
    // console.log(JSON.parse(myToken).access_token);
    let json = this.ks.parseJwt(JSON.parse(myToken).access_token);
    console.log(json);

    this.userName = json.email.substring(0, json.email.indexOf('@'));
    console.log(this.userName);
    this.ls.uname=this.userName

    //////////////////////////////chat initiated////////////////////////////////////////////////
    const options = { responseType: 'text' as 'text' };
    this.http
    .post(
          'https://ai.onwingspan.com/nodeapi/3001/bot/initiate'
,

      { user: this.userName},options
    ).subscribe({next:()=>{
       console.log('Chat Initiated');
       
    },error:(err)=>{
     console.log(err,'Error Occurred');
     
    }})

    /////////////////////////////////////////////
    this.chatArray[this.triggerCount] = [];

    this.chatArray[this.triggerCount].push('Hi There , How do you want to learn today ?');
    this.flag=true;
    // this.chatArray[this.triggerCount].push('How Can I help You?');
    this.ldArr[this.triggerCount] = false;
    this.timer[this.triggerCount] =
      new Date().getHours() + ':' + new Date().getMinutes();
  }
  //////////////////////////////////////chatbot///////////////////////////////////////////////
  getChatResponse(input) {
    console.log(input);

    if (input && this.triggerCount % 2 == 0 && this.ldArr[this.triggerCount]) {
      this.scrollInterval = setInterval(() => {
        this.count += 200;
        this.scroll();
      }, 500);
      console.log('value');
      this.userInput = '';
      this.triggerCount += 1;
      this.chatArray[this.triggerCount] = [];

      this.chatArray[this.triggerCount].push(input);
      this.timer[this.triggerCount] =
        new Date().getHours() + ':' + new Date().getMinutes();
      this.ldArr[this.triggerCount] = true;
      //response
      this.triggerCount += 1;
      this.ldArr[this.triggerCount] = false;
      // 
      this.http
        .post(
          'https://ai.onwingspan.com/nodeapi/3001/bot/chat',

          { user: this.userName, conversation: input ,qtype:this.qType}
        )
        .subscribe({
          next: (val: any) => {
            console.log('res', val);
            this.userInput = '';
            this.chatArray[this.triggerCount] = [];

            this.chatArray[this.triggerCount].push(val.message);

            setTimeout(() => {
              this.ldArr[this.triggerCount] = true;
              this.timer[this.triggerCount] =
                new Date().getHours() + ':' + new Date().getMinutes();
              //sample
              setTimeout(() => {
                clearInterval(this.scrollInterval);
              }, 2000);
            }, 2000);
          },

          error: () => {
            this.userInput = '';
            this.chatArray[this.triggerCount] = [];

            this.chatArray[this.triggerCount].push(
              'we’re sorry, we are unable to help right now :(.'
            );

            setTimeout(() => {
              this.ldArr[this.triggerCount] = true;
              this.timer[this.triggerCount] =
                new Date().getHours() + ':' + new Date().getMinutes();
              //sample
              setTimeout(() => {
                clearInterval(this.scrollInterval);
              }, 2000);
            }, 2000);
          },
        });
    }
  }

  refresh(){
    this.flag=false
    this.triggerCount = 0;

      this.chatArray = [];
    
      this.ldArr = [];
    
    
    
      this.timer = [];
      const options = { responseType: 'text' as 'text' };
    
      this.http
    
      .post(
    
       'https://ai.onwingspan.com/nodeapi/3001/bot/initiate',
    
    
    
    
       { user: this.userName},options
    
      ).subscribe({next:()=>{
    
       console.log('Chat Initiated');
    
       this.chatArray[this.triggerCount] = [];
    
    this.flag=true
    
    
       this.chatArray[this.triggerCount].push('Hi There');
    
       this.chatArray[this.triggerCount].push('Hi There , How do you want to learn today ?');
    
        this.ldArr[this.triggerCount] = false;
    
     
    
    
    
    
        /////
    
        setTimeout(() => {
    
         this.ldArr[this.triggerCount] = true;
    
         this.timer[this.triggerCount] =
    
          new Date().getHours() + ':' + new Date().getMinutes();
    
        
    
        
    
        }, 2000);
    
        
    
      },error:(err)=>{
    
      console.log(err,'Error Occurred');
    
      
    
      }})

    /////////////////////////////////////////////
 

  }


  learningType(arg:any){
    this.flag=false;
    this.qType = arg;
    this.chatArray[this.triggerCount].push(`Great, let's do  ${arg} learning `);
    this.chatArray[this.triggerCount].push('How Can I help You?');

   

  }
    //////////////////////////////////////chatbot///////////////////////////////////////////////
  getDate() {
    return new Date().getHours() + ':' + new Date().getMinutes();
  }
  //////////////////////////////////////chatbot///////////////////////////////////////////////
  startLoader() {
    if (this.triggerCount == 0) {
      setTimeout(() => {
        this.ldArr[this.triggerCount] = true;
        //sample
      }, 2000);
    }
  }
  //////////////////////////////////////chatbot///////////////////////////////////////////////
  checkLoader() {
    if (this.triggerCount == 0) {
      this.ldArr[this.triggerCount] = false;
    }
  }
  //////////////////////////////////////chatbot///////////////////////////////////////////////
  checkKey(event) {
    // console.log(event);


    this.startLoader();

    if (event.key == 'Enter') {
      this.getChatResponse(this.userInput);
      this.userInput = '';
     
     
    }

   
  }
  //////////////////////////////////////chatbot///////////////////////////////////////////////
  scroll() {
    console.log('done');
 
 
    var objDiv = document.getElementById('data');
    objDiv.scrollTop = objDiv.scrollTop + this.count;
  }
    //////////////////////////////////////chatbot///////////////////////////////////////////////
  pulse = false;
  recorder(chat) {
    this.pulse = true;
    record(chat);
    setTimeout(() => {
      this.pulse = false;
      console.log((document.getElementById('inp') as HTMLInputElement).value);

      this.getChatResponse(
        (document.getElementById('inp') as HTMLInputElement).value
      );
      (document.getElementById('inp') as HTMLInputElement).value=""
    }, 4000);
  }
}
