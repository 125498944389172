import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeof'
})
export class TypeofPipe implements PipeTransform {

  transform(value: any): any {
    
    if(typeof value == "string"){
      return value
    }
    else {
      return value.option
    }
  }

}
