
<div class="container mt-3 " style="color:white">
    <div class="row">
        <div class="col-4 mb-3 d-flex justify-content-end">
            <label for="org_input" style="font-size: 1.3em;">Organization</label>
        </div>
        <div class="col-4">



            <input class="form-control" type="text" placeholder="Provide Organization Name" id="org_input" #org_input>





            <!-- Modal -->
            <div class="modal fade" id="exampleModal" style="color:black" tabindex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">{{process_sel | titlecase}}</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>




                        <div class="modal-body">

                            <div class="d-flex justify-content-center" *ngIf="!domain_content">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden"></span>
                                </div>

                            </div>

                            <div *ngIf="domain_content" [innerHTML]="domain_content">
                              

                            </div>


                        </div>

                        <div class="modal-footer" style="background-color: #010028">
                            <div class="container-fluid">

                                <div class="row">

                                    <div class="col">

                                        
                                        <a class="p-1 rounded" style="color:#62eec7;cursor: pointer;"
                                            (click)="info_content('performance')">Performance</a>

                                        <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                            (click)="info_content('acquisition')">Acquisition</a>

                                        <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                            (click)="info_content('leadership')">Leadership</a>

                                        <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                            (click)="info_content('evolution')">Evolution</a>

                                        <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                            (click)="info_content('future')">Future</a>
                                        <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                            (click)="info_content('growth')">Growth</a>
                                        <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                            (click)="info_content('swot')">Swot</a>
                                        <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                            (click)="info_content('culture')">Culture</a>
                                        

                                        <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                        (click)="info_content('revenue')">Revenue</a>
                                    <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                        (click)="info_content('innovation')">Innovation</a>
                                        <br />
                                        <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                        (click)="info_content('sustainability')">Sustainability</a>
                                        <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                                        (click)="info_content('services and platforms')">Services&Platforms</a>
                                     


                                    </div>






                                </div>

                            </div>




                        </div>

                    </div>
                </div>
            </div>






        </div>

        <div class="col-4">

            <button type="button" class="btn btn-light" (click)="domain_selected(org_input.value)">Generate</button>
        </div>

    </div>

    <br />
    <br />
    <br />

    <div class="row row-cols-md-4  g-5 justify-content-center text-center" *ngIf="domain_sel">

        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/performance.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Performance</h5>
                    <p class="card-text" style="color:black">

                        The performance of a company refers to how well the organization is achieving its goals and objectives

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('performance')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <!-- <button type="button" class="btn btn-light" style="background-color: #62eec7;" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="info_content('knowledge')" >Info</button> -->
                </div>
            </div>

        </div>


        <div class="col">

            <div class="card h-100" style="width: 16rem;height: 21.875em;">
                <img src="https://edtech-s3.onwingspan.com/ai/aquisition.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Acquisition</h5>
                    <p class="card-text" style="color:black">
Process of one company purchasing another company, either through a merger or acquisition.

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('acquisition')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>

        </div>






        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/leadership.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Leadership</h5>
                    <p class="card-text" style="color:black">

                        ability to inspire, motivate, and guide individuals or groups towards a common goal or vision
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('leadership')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>

        </div>


        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/evolution.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Evolution</h5>
                    <p class="card-text" style="color:black">

                        Evolution refers to the gradual process of change and development over time in organization

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('evolution')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>

        </div>


        
        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/future.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Future</h5>
                    <p class="card-text" style="color:black">

                        Future refers to organizations potential for growth, success, and sustainability in the long term

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('future')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>

        </div>


        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/growth.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Growth</h5>
                    <p class="card-text" style="color:black">

                        Growth is a term used to describe the increase in size, value, or performance of a company over time

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('growth')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>

        </div>

        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/swot.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">SWOT</h5>
                    <p class="card-text" style="color:black">

                        SWOT analysis is a tool used to evaluate a company's strengths, weaknesses, opportunities, and threat.

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('swot')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>

        </div>

        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/culture.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Culture</h5>
                    <p class="card-text" style="color:black">

                        Culture refers to the shared values and practices that shape the overall work environment in the organization

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('culture')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>

        </div>


        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/revenue.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Revenue</h5>
                    <p class="card-text" style="color:black">
                        Revenue refers to the total income it generates from the sale of its products or services over a specific period of time.

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('revenue')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>



        </div>


        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/innovation.jpg" class="card-img-top imgg"
                    loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Innovation</h5>
                    <p class="card-text" style="color:black">

                        Creating and implementing new ideas, methods, products, or services that add value and improve performance

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('innovation')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>

        </div>

        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/sustainability.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Sustainability</h5>
                    <p class="card-text" style="color:black">

                        Ability to  meet the needs of the present without compromising the ability of future generations

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('sustainability')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>



        </div>

        <div class="col">

            <div class="card h-100" style="width: 16rem;height:21.875em">
                <img src="https://edtech-s3.onwingspan.com/ai/platforms.jpg" class="card-img-top imgg" loading="lazy">
                <div class="card-body">
                    <h5 class="card-title" style="color:black">Services & Platforms</h5>
                    <p class="card-text" style="color:black">

                        Services and Platforms refer to the products it offers and the digital or technological tools it uses to deliver them

                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        (click)="info_content('services and platforms')" width="16" height="16" fill="currentColor"
                        class="bi bi-info-circle icon" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
            </div>



        </div>





    </div>


 










</div>
