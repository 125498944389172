import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class CustomInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes("/auth")) {
            let myToken = localStorage.getItem("synthaiz");
            console.log(myToken);
            
            if (myToken) {
                const headers = new HttpHeaders({
                    'Authorization': "Bearer " + JSON.parse(myToken).access_token
                });
                request = request.clone({ headers });
            }
        }
        return next.handle(request)
            .pipe(
                tap(_ => { }),
                timeout(600000)
            );


    }

}