import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {speech} from '../speech.js'
class wrapper {
  message: any
}
@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css']
})
export class GlossaryComponent implements OnInit {

  errorMsg;
  glossary_list:any="";
  glossary_selected:boolean=false
  sub_glossary_data: any="";
  sub_glossary_selected=false
  formattedData:any="";

  selectedLibrary:any=""

  constructor(private http:HttpClient) { }

  ngOnInit(): void {
 
  }


  glossary1(lib:string,words?: any) {

    this.formattedData=""

    this.errorMsg=""

    this.selectedLibrary=lib

    let query;


this.sub_glossary_data=""
    this.glossary_selected=true
    this.sub_glossary_selected=false

    if(words.length>0){
      
   query =`get me glossary of words for ${lib} . Include only words without any explanation .Make sure to include the words such as ${words} in the list.
    Generate it in the array format only`

    }

    else{
      
    query =`get me glossary of words for ${lib} . Include only words without any explanation .
    Generate it in the array format only`
    }
   

    this.http.post<wrapper>("https://editor.iap.infosysapps.com/54-254-249-144/vscode/3001/mcq/glossary",{format:query})
      .subscribe(data => {

      
          console.log("returned glossary", data.message[0].text)
          this.glossary_list=data.message[0].text.replace(/\[|\]/g,'').split(',')
          this.glossary_selected=false
          this.errorMsg=""

        

      

      })


    setTimeout(() => {

     

    
      if (this.glossary_list == "" && this.glossary_selected) {
        console.log('glossary timeout')
        this.errorMsg = "Your Request is taking more time, You may try again"
      }


    }, 20000);



  }



  sub_glossary(arg:any){

    const a=arg.replace(/[']/g, '').replace(/\n/g, '').replace(/\s+/g, ' ').trim()

    
    this.formattedData=""


    this.errorMsg=""
    this.sub_glossary_selected=true

    this.sub_glossary_data=""

    let query=`explain ${a} of ${this.selectedLibrary} in terms of concept , fact , principle , procedure and process. Concept , Fact , principle , procedure and  process  should be keys in json format`

    this.http.post<wrapper>("https://editor.iap.infosysapps.com/54-254-249-144/vscode/3001/mcq/words",{format:query})
    .subscribe(data => {

  
      try{

        this.sub_glossary_data=JSON.parse(data.message[0].text.replace(/\\n/g, "\\n"))
        // .replace(/\\'/g, "\\'")
        // .replace(/\\"/g, '\\"')
        // .replace(/\\&/g, "\\&")
        // .replace(/\\r/g, "\\r")
        // .replace(/\\t/g, "\\t")
        // .replace(/\\b/g, "\\b")
        // .replace(/\\f/g, "\\f"))
       
        this.sub_glossary_selected=false
        this.errorMsg=""
       

      }

      catch(err){

        this.formattedData=data.message[0].text.replace(/[{}",]/g, '')
        this.sub_glossary_selected=false
        this.errorMsg=""


      }

      // finally{
      //   speech()
      // }

      

    })

    setTimeout(() => {

    
      if (this.sub_glossary_data == "" && this.sub_glossary_selected) {
        console.log('sub glossary timeout')
        this.errorMsg = "Your Request is taking more time, You may try again"
      }


    }, 20000);

  }


  text_speech(arg:any){

    speech(arg)

  }


}
