import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lv'
})
export class LvPipe implements PipeTransform {

  transform(value: any): any {
    
    if(value=="five"){
      return "Beginner"
    }

   

    return value;
  }

}
