<div class="container-fluid mt-3   h-100 overflow-auto" style="color:white">

  <div class="modal fade" id="exampleModal" style="color:black" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered my-modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">{{process_sel | titlecase}}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>




        <div class="modal-body">

          <div class="d-flex justify-content-center" *ngIf="!domain_content">
            <div class="spinner-border" role="status">
              <span class="visually-hidden"></span>
            </div>

          </div>

          <div *ngIf="domain_content" [innerHTML]="domain_content">

            <!-- {{domain_content.html}} -->

          </div>


        </div>

        <div class="modal-footer" style="background-color: #010028">
          <div class="container-fluid">

            <div class="row">

              <div class="col">


                <a class="p-1 rounded" style="color:#62eec7;cursor: pointer;"
                  (click)="info_content('knowledge')">Knowledge</a>

                <a class="p-1 rounded" style="color:#62eec7;cursor:pointer" (click)="info_content('mega trends')">Mega
                  Trends</a>
                <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                  (click)="info_content('challenges')">Challenges</a>
                <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                  (click)="info_content('culture')">Culture</a>
                <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                  (click)="info_content('evolution')">Evolution</a>
                <a class="p-1 rounded" style="color:#62eec7;cursor:pointer" (click)="info_content('future')">Future</a>
                <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                  (click)="info_content('projects')">Projects</a>
                <a class="p-1 rounded" style="color:#62eec7;cursor:pointer" (click)="info_content('top players')">Top
                  Players</a>
                <br />
                <a class="p-1 rounded" style="color:#62eec7;cursor:pointer"
                  (click)="info_content('industry analysis')">Industry Analysis</a>
                <a class="p-1 rounded" style="color:#62eec7;cursor:pointer" (click)="info_content('case study')">Case
                  Study</a>
              </div>







            </div>

          </div>




        </div>

      </div>
    </div>
  </div>


  <ng-container *ngIf="!domain_sel">
    <div class="row ">
      <div class="col-4 mb-3 d-flex justify-content-end  ">
        <label for="domain_input" style="font-size: 1.3em;">Domain</label>
      </div>
      <div class="col-4  ">



        <input class="form-control " type="text" placeholder="Provide Domain Name" id="domain_input" #domain_input>










      </div>

      <div class="col-4">

        <button type="button" class="btn btn-light" (click)="domain_selected(domain_input.value)">Generate</button>
      </div>

    </div>


    <div class="row mt-3">
      <div class="col-6 col-md-2 " *ngFor="let doma of domains">
        <div class="card h-100 custom-card" role="button" (click)="domain_selected(doma.name)">
          <img [src]="doma.img" class="img-fluid" style="height: 12rem;">
          <div class="text-center fw-500">
            {{doma.name | uppercase}}
          </div>
        </div>

      </div>
    </div>
  </ng-container>

  <div *ngIf="domain_sel">
    <span (click)="domain_sel=null" class="back-arrow">&#9664;</span> {{domain | uppercase}}
  </div>

  <div class="row mt-2 row-cols-md-4 g-4 justify-content-center text-center" *ngIf="domain_sel">
   

    <div class="col">

      <div class="card h-100" style="width: 16rem;height:21.875em">
        <img src="https://edtech-s3.onwingspan.com/ai/knowledge.jpg" class="card-img-top imgg" loading="lazy">
        <div class="card-body">
          <h5 class="card-title" style="color:black">Knowledge</h5>
          <p class="card-text" style="color:black">

            Domain knowledge refers to specialized knowledge or expertise in a particular field or subject area

          </p>
          <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="info_content('knowledge')" width="16" height="16" fill="currentColor"
            class="bi bi-info-circle icon" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>

        </div>
      </div>
    </div>






    <div class="col">

      <div class="card  h-100" style="width: 16rem;height:350px">
        <img src="https://edtech-s3.onwingspan.com/ai/trends.jpg" class="card-img-top imgg" loading="lazy">
        <div class="card-body">
          <h5 class="card-title" style="color:black">Mega Trends</h5>
          <p class="card-text" style="color:black">

            Long-term driving forces that are observable now and will most likely have a global impact

          </p>
          <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="info_content('mega trends')" width="16" height="16" fill="currentColor"
            class="bi bi-info-circle icon" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
      </div>

    </div>






    <div class="col">

      <div class="card  h-100" style="width: 16rem;height:350px">
        <img src="https://edtech-s3.onwingspan.com/ai/challenges.jpg" class="card-img-top imgg" loading="lazy">
        <div class="card-body">
          <h5 class="card-title" style="color:black">Challenges</h5>
          <p class="card-text" style="color:black">

            A demand to explain, justify, or difficulty in a undertaking that is stimulating to one engaged in it

          </p>
          <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="info_content('challenges')" width="16" height="16" fill="currentColor"
            class="bi bi-info-circle icon" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
      </div>

    </div>


    <div class="col">

      <div class="card  h-100" style="width: 16rem;height:350px">
        <img src="https://edtech-s3.onwingspan.com/ai/culture.jpg" class="card-img-top imgg" loading="lazy">
        <div class="card-body">
          <h5 class="card-title" style="color:black">Culture</h5>
          <p class="card-text" style="color:black">

            Shared beliefs, values and practices that characterize or shape up an organization or company.

          </p>
          <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="info_content('culture')" width="16" height="16" fill="currentColor" class="bi bi-info-circle icon"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
      </div>

    </div>



    <div class="col">

      <div class="card  h-100" style="width: 16rem;height:350px">
        <img src="https://edtech-s3.onwingspan.com/ai/evolution.jpg" class="card-img-top imgg" loading="lazy">
        <div class="card-body">
          <h5 class="card-title" style="color:black">Evolution</h5>
          <p class="card-text" style="color:black">

            Evolution refers to the changes and developments that occur within a particular field over time

          </p>
          <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="info_content('evolution')" width="16" height="16" fill="currentColor"
            class="bi bi-info-circle icon" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
      </div>

    </div>


    <div class="col">

      <div class="card h-100" style="width: 16rem;height:350px">
        <img src="https://edtech-s3.onwingspan.com/ai/future.jpg" class="card-img-top imgg" loading="lazy">
        <div class="card-body">
          <h5 class="card-title" style="color:black">Future</h5>
          <p class="card-text" style="color:black">

            Potential developments, trends, and opportunities that may arise within a particular industry

          </p>
          <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="info_content('future')" width="16" height="16" fill="currentColor" class="bi bi-info-circle icon"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
      </div>

    </div>

    <div class="col">

      <div class="card" style="width: 16rem;height:350px">
        <img src="https://edtech-s3.onwingspan.com/ai/projects.jpg" class="card-img-top imgg" loading="lazy">
        <div class="card-body">
          <h5 class="card-title" style="color:black">Projects</h5>
          <p class="card-text" style="color:black">

            Projects refer to specific initiatives aimed at achieving a defined goal within a particular field.

          </p>
          <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="info_content('projects')" width="16" height="16" fill="currentColor" class="bi bi-info-circle icon"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
      </div>

    </div>

    <div class="col">

      <div class="card h-100" style="width: 16rem;height:350px">
        <img src="https://edtech-s3.onwingspan.com/ai/top_players.jpg" class="card-img-top imgg" loading="lazy">
        <div class="card-body">
          <h5 class="card-title" style="color:black">Top Players</h5>
          <p class="card-text" style="color:black">

            Leading organizations having significant influence, expertise, and achievements in the field of domain

          </p>
          <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="info_content('top players')" width="16" height="16" fill="currentColor"
            class="bi bi-info-circle icon" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
      </div>

    </div>


    <div class="col">

      <div class="card h-100" style="width: 16rem;height:350px">
        <img src="https://edtech-s3.onwingspan.com/ai/industry_analysis.jpg" class="card-img-top imgg" loading="lazy">
        <div class="card-body">
          <h5 class="card-title" style="color:black">Industry Analysis</h5>
          <p class="card-text" style="color:black">

            Process of examining trends, opportunities, threats, and competition within a specific industry.

          </p>
          <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="info_content('industry analysis')" width="16" height="16" fill="currentColor"
            class="bi bi-info-circle icon" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
      </div>

    </div>

    <div class="col">

      <div class="card h-100" style="width: 16rem;height:350px">
        <img src="https://edtech-s3.onwingspan.com/ai/case_study.jpg" class="card-img-top imgg" loading="lazy">
        <div class="card-body">
          <h5 class="card-title" style="color:black">Case Study</h5>
          <p class="card-text" style="color:black">

            Analysis of a particular situation, event, organization, or individual within a specific industry

          </p>
          <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="info_content('case study')" width="16" height="16" fill="currentColor"
            class="bi bi-info-circle icon" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
      </div>









    </div>