<div class="container row mt-3 mx-5  ">

  <div class="col-md-3 d-flex justify-content-start   align-items-start">
    <label for="enterAnyTask" style="color: white;" class="d-flex justify-content-start mt-2 mx-3" >Provide Topic for
      creating TOC</label>
  </div>
  <div class="col-md-3 justify-content-start">
    <input #course class="form-control form-control" type="text" placeholder="Course Name"
      aria-label=".form-control-lg example" id="p">



  </div>

  <div class="col-md-4  d-flex justify-content-center  align-items-center  ">

    <svg (click)=" recorder()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="do"
      class="bi bi-mic acc " viewBox="0 0 16 16">
      <path
        d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
    </svg>
    &nbsp;
    <button type="button" class="btn btn-light ms-2" (click)="courseToc(course.value)" id="move">Generate</button>
    &nbsp;&nbsp;&nbsp;&nbsp;

    <div id="mode ">
      <label style="color: white;" id="do">AI Model</label>
      &nbsp;&nbsp;
      <a class="btn btn-light dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        GPT-4
      </a>

      <ul class="dropdown-menu"  id="drop">
        <li><a class="dropdown-item">AI-Cloud (Coming Soon!)</a></li>
        <li><a class="dropdown-item">BLOOM (Coming Soon!)</a></li>
        <li><a class="dropdown-item">LaMDA (Coming Soon!)</a></li>


      </ul>

    </div>
  </div>






  <div class="col-md-2  " >



    <label style="color: white;" id="do">Level</label>
    &nbsp;&nbsp;

    <a class="btn btn-light dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      {{level | lv | titlecase}}
    </a>

    <ul class="dropdown-menu">
      <li><a class="dropdown-item" (click)="change_level('five')">Beginner</a></li>
      <li><a class="dropdown-item" (click)="change_level('moderate')">Moderate</a></li>
      <li><a class="dropdown-item" (click)="change_level('advanced')">Advanced</a></li>

    </ul>

  </div>






</div>






<br><br>






<div role="status" style="margin-left: 45%;color:white" *ngIf="description.length == 0 && flag == true">
  <span class="spinner-border spinner-border-lg"></span>&nbsp;

</div>

<br />
<div style="margin-left: 35%;color:white" *ngIf="courseError != ''">
  {{courseError}}
</div>


<div class="row mt-5" style="margin-left:100px" *ngIf="description.length != 0">


  <div class="col-md-4">


    <div class="accordion" id="accordionExample" style="max-height: 400px;overflow:auto">


      <div *ngFor="let course of description,let p=index;">
        <div class="accordion-item">

          <h2 class="accordion-header">

            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#'+course[0].split(' ').join('')" aria-expanded="true"
              aria-controls="collapseOne">
              {{course[0]}}
            </button>
          </h2>


          <div [id]="course[0].split(' ').join('')" class="accordion-collapse collapse " aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">

            <div *ngFor="let c of course, let i=index">
              <div class="accordion-body topic" *ngIf="i>0">

                <a (click)="topic(c)">{{c}}</a>


              </div>
            </div>

          </div>
        </div>
      </div>



    </div>



  </div>


  <div class="col-md-7">






    <div *ngIf="selectedTopic && optionsFlag">
      <svg xmlns="http://www.w3.org/2000/svg" width="100" (click)="retry()" height="100" fill="currentColor"
        class="bi bi-arrow-clockwise acc" viewBox="0 0 16 16" id="go">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />

      </svg>

      &nbsp;
      <span style="color:white" id="me">Retry Response</span>

      &nbsp;




      <svg xmlns="http://www.w3.org/2000/svg" width="16" (click)="mcqs()" height="16" fill="currentColor" id="go"
        class="bi bi-list-task acc" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z" />
        <path
          d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
        <path fill-rule="evenodd"
          d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z" />
      </svg>
      &nbsp;
      <span style="color:white" id="me">Quiz Me!</span>
      &nbsp;

      <svg xmlns="http://www.w3.org/2000/svg" width="16" (click)="exercise()" height="16" fill="currentColor" id="go"
        class="bi bi-question-circle acc" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path
          d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
      </svg>
      &nbsp;
      <span style="color:white" id="me">Demo</span>

      &nbsp;

      <svg xmlns="http://www.w3.org/2000/svg" (click)="interview()" width="16" height="16" fill="currentColor" id="go"
        class="bi bi-camera-video acc" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z" />
      </svg>

      &nbsp;
      <span style="color:white" id="me">Interview Me!</span>
      &nbsp;

      &nbsp;

      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate acc"
        id="go" viewBox="0 0 16 16">
        <path
          d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
        <path
          d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
      </svg>
      &nbsp;


      <span style="color:white" class="dropdown" id="me">

        <a class="btn btn-light dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{lang}}
        </a>

        <ul class="dropdown-menu">
          <li><a class="dropdown-item" (click)="change_lang('English')">English</a></li>
          <li><a class="dropdown-item" (click)="change_lang('Hindi')">Hindi</a></li>

          <li><a class="dropdown-item" (click)="change_lang('French')">French</a></li>
          <li><a class="dropdown-item" (click)="change_lang('German')">German</a></li>



        </ul>




      </span>
    </div>

    <br />


    <div style="margin-left: 50%;color:white"
      *ngIf="topicDes == '' && mcqsDes == '' && interviewDes == '' && errorMsg == '' && exerciseDes == ''">
      <span class="spinner-border spinner-border-lg"></span>&nbsp;

    </div>


    <div *ngIf="errorMsg != ''" style="margin-left: 20%;color:white">
      {{errorMsg}}
    </div>


    <!-- <textarea *ngIf="selectedTopic && topicSel && optionsFlag  && topicDes != ''" class="form-control" id="topicSpeech"
      style="height:300px;" aria-label="With textarea" [(ngMode)]="topicDes">

        {{topicDes}}
      </textarea> -->


    <div class="textarea-like" *ngIf="selectedTopic && topicSel && optionsFlag  && topicDes != ''"
      [innerHTML]="topicDes"></div>

    <div *ngIf="selectedTopic && exerciseSel && optionsFlag  && exerciseDes != ''" >

      <ngx-monaco-editor style="height:350px;" [options]="editorOptions" [(ngModel)]="exerciseDes.message">
      </ngx-monaco-editor>



      <div class="accordion" id="accordion2" >
        <div class="accordion-item">
          <h2 class="accordion-header" id="One">
            <button (click)="explain()" class="accordion-button" type="button" data-bs-toggle="collapse"
              data-bs-target="#ac1">
              Code Explanation
            </button>
          </h2>
          <div id="ac1" class="accordion-collapse collapse" data-bs-parent="#accordion2">
            <div class="accordion-body">
              <span class="spinner-border spinner-border-lg" *ngIf="explainDes == ''"></span>
              <textarea class="form-control" *ngIf="explainDes != ''" style="height:250px;" aria-label="With textarea">
        {{explainDes.message || explainDes}}
      </textarea>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="Two">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ac2">
              Code Execution
            </button>
          </h2>
          <div id="ac2" class="accordion-collapse collapse" data-bs-parent="#accordion2">
            <div class="accordion-body">
              Feature in Development !
            </div>
          </div>
        </div>

      </div>








      <br />



    </div>


    <div *ngIf="mcqSel && optionsFlag">



      <div class="row" >

        <div class="card" style="height:300px">
          <div class="card-body">

            <h5 class="card-title">Q:{{mcqsDes.question || mcqsDes.Question}}</h5>
            <div *ngIf="mcqsDes.options|| mcqsDes.Options">
              <div *ngFor="let option of (mcqsDes.options || mcqsDes.Options);let p=index">

                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="p+1">
                  <label class="form-check-label" for="p+1">
                    <span>{{option | typeof}}</span>


                  </label>
                </div>

              </div>
            </div>
            <br />

            <a class="btn" (click)="mcqsDes.show=true" style="background-color:#62eec7;color:#010028" >Show Answer</a>
            &nbsp;&nbsp;
            <a class="btn" (click)="mcqs()" style="background-color:#62eec7;color:#010028" >Next Question</a>
            <br /><br />
            <p *ngIf="mcqsDes.show">{{mcqsDes.answer || mcqsDes.correctAnswer || mcqsDes.Answer || "Format Error :
              Proceed to next question"}}</p>
          </div>
        </div>


      </div>

    </div>




    <div *ngIf="interviewSel && optionsFlag"  id="inter">



      <div class="row">
        <div>
          <div class="card" style="height:300px">
            <div class="card-body">
              <!-- <h4 class="card-title">Level : {{mcq.complexity || mcq.Complexity || mcq.level || mcq.Level}}</h4> -->
              <h5 class="card-title">Q:{{interviewDes.question || interviewDes.Question ||
                interviewDes.interviewQuestion}}</h5>

              <br />

              <a class="btn" (click)="interviewDes.show=true" style="background-color:#62eec7;color:#010028" >Show
                Answer</a>
              &nbsp;&nbsp;
              <a class="btn" (click)="interview()" style="background-color:#62eec7;color:#010028">Next Question</a>
              <br /><br />
              <p *ngIf="interviewDes.show">{{interviewDes.answer || interviewDes.correctAnswer || interviewDes.Answer}}
              </p>
            </div>
          </div>
        </div>

      </div>

    </div>



  </div>








  <div class="col-md-1 mt-5">

    <div class="container" *ngIf="selectedTopic && topicSel && optionsFlag  && topicDes != '' ">

      <div class="row">
        <svg (click)="text_speech('topicSpeech')" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
          fill="currentColor" class="bi bi-volume-up" viewBox="0 0 16 16" style="font-size: 80px;color: white;">
          <path
            d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
          <path
            d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" />
          <path
            d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z" />
        </svg>

      </div>

      <div class="row">

        <svg (click)="text_speech('mute')" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-pause-fill " viewBox="0 0 16 16" style="font-size: 80px;color: white;">
          <path
            d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
        </svg>







      </div>

    </div>





  </div>

</div>