import { Component, OnInit } from '@angular/core';
import { KeyCloakService } from '../keycloak.service';

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.css']
})
export class EmbedComponent implements OnInit {

  constructor(public ks: KeyCloakService) {
    window.addEventListener("message", (event) => {
      if (event.data && event.data.requestId == "LOADED") {
        let user = this.ks.user;
        //@ts-ignore
        let win = (document.getElementById("glossaryAI")).contentWindow;
        // win.postMessage({}, "*")
        let pageDataToSend = {
          "app": "SynthAIz",
          "type": "LOADED",
          "parentContext": {
            "domainName": "wingspan-dev.infosysapps.com",
            "playerData": null,
            "rootOrg": "Infosys",
            "org": "Infosys Ltd",
            "theme": {
              "name": "Cobalt Theme",
              "warn": "#ff2640",
              "accent": "#aa32be",
              "primary": "#62eec7",
              "primaryBackground": "#010028",
              "tertiaryBackground": "#ffffff",
              "secondaryBackground": "#f3f4ef"
            },
            "fontSize": "14px",
            "v2ThemeConfig": {
              "isDarkMode": false,
              "primaryColor": "#62eec7",
              "primaryFontColor": "#01014a",
              "warningColor": "#ff2640",
              "disabledColor": "#b1b1c5",
              "backgroundFontColor": "#fff",
              "primaryBackgroundFontColor": "#010028",
              "secondaryBackgroundFontColor": "#f3f4ef",
              "secondaryColor": "#fff",
              "secondaryFontColor": "#53537c",
              "highlightColor": "#aa32be",
              "outlineBorderColor": "#53537c",
              "fontSize": "14px",
              "fontFamily": "SiemensSans,Roboto,Open Sans",
              "fontColor": "#01014a"
            },
            "locale": "en",
            "darkMode": false,
            "subApplicationStartMode": "START",
            "user": user
          },
        }
        win.postMessage(pageDataToSend, "*")
      }
    })
  }

  ngOnInit(): void {
  }

}
