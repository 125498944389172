
<div id="playGround-container" style="display:flex;height:530px" class="mt-3">
    <div id="editor-container">
  
      <div id="drop-down" class=" container mt-3">
  
       <button type="button" class="btn btn-light" (click)="onSubmit()" style="width: 90px;">Run</button>
  
      </div>
  
  
  
  
      <div class="container overflow-auto " style="color: white;">
       
        <textarea [(ngModel)]="inputData" style="width: 617px;height: 430px;"></textarea>
  
  
  
      </div>
  
     
    
    </div>
  
    <!-- <div id="drag-bar" class="mt-3 "></div> -->
    <div id="sideBar" class="mt-3" style="color:white">
        <div id="down" class="container"  >
  
            <!-- <button type="button" class="btn btn-light">Download</button> -->
            

            <button type="button"   id="button"  [disabled]="flag" style="position: relative;margin-left: -12px;" ><a [href]="downloadUrl" [download]="fileName" >Download HTML File</a></button>&nbsp;&nbsp;&nbsp;&nbsp;

            <div class="spinner-border" role="status" *ngIf="flag" >
                <span class="visually-hidden">Loading...</span>
              </div>
       
           </div>

      <textarea style="width: 610px;height: 430px;" >{{outputData}}
       </textarea>
  
    
    </div>
  </div>