exports.speech=function(arg,lang){
     
        if (!"speechSynthesis" in window) {
            alert("Sorry, your browser doesn't support text to speech!");
            return;
        }

       else if(arg=='mute'){

        console.log("mute")
            const synth = window.speechSynthesis;

            synth.pause();

            
        }

        else{

 

      
            console.log("sound",arg)

       
    
        // const button = document.getElementById("speak");
        // const pauseButton = document.getElementById("pause");
        // const voiceSelect = document.getElementById("voices");
        const synth = window.speechSynthesis;
        // const voices = synth.getVoices();
        
        if(arg=='topicSpeech'){

            const main = document.querySelector('#'+arg);
            textToSpeech(main.value);

        }

        else{
            const main = document.querySelector('#'+arg);
      
            textToSpeech(main.innerText);

        }
       
       
    
        function textToSpeech(text) {
            const msg = new SpeechSynthesisUtterance();
            msg.text = text;
           
          

            if(lang == 'Hindi'){
                msg.lang = 'hi-IN';
            }

                 if(lang == 'French'){
                msg.lang = 'fr-FR';
            }

              if(lang == 'German'){
                msg.lang = 'de-DE';
            }


            console.log(msg)
            window.speechSynthesis.cancel();
    
            synth.speak(msg);
        }
    
        

    }

    
}

   
    
