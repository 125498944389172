<div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card shadow">
          <div class="card-body">
            <!-- <h4 class="card-title text-center mb-4">Login</h4> -->
            <form>
              <div class="form-group mb-3">
                <label for="email" class="form-label" style="font-weight:bold" >Username</label>
                <input type="email" class="form-control" #username id="email" placeholder="Enter your email">
              </div>
              <div class="form-group mb-3">
                <label for="password" class="form-label" style="font-weight:bold" >Password</label>
                <input type="password" class="form-control" #password id="password" placeholder="Enter your password">
              </div>
              <button style="background-color: #62eec7;color:black;font-weight:bold" type="submit" class="btn btn-primary w-100" (click)="login(username.value,password.value)">Login</button>
              <br/>
              <div>
                <p style="color:red" *ngIf="errorMsg != ''">{{errorMsg}}</p>
              </div>
            </form>
            <hr>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  