import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css']
})
export class ThemeComponent implements OnInit {


  inputData:string="Paste some data here";
  outputData:string="";
  flag:boolean;

  constructor(private cdr:ChangeDetectorRef, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }
//
  onSubmit(){
    this.outputData=""
    let source = new EventSource(`https://ai.onwingspan.com/nodeapi/3001/mcq/theme?content=${this.inputData}`);
    source.onmessage = (event)=> {
      
    
      this.flag = true;

      if (event.data == '[DONE]') {

        
     
        source.close()

        this.flag= false;
  this.cdr.detectChanges()

 
  
   
      } else {
       
        if(event.data != "undefined"){
          this.outputData += event.data;
          
        }

       

        
       
      
        this.cdr.detectChanges();
       


       
        
      }
      
    };
  }


  fileName = 'index.html';

 
    get downloadUrl(): SafeUrl {
        const blob = new Blob([this.outputData], { type: 'text/html' });
        const url = window.URL.createObjectURL(blob);
        return this.sanitizer.bypassSecurityTrustUrl(url);
      }

}
