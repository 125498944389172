import { Component } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  constructor(private lservice:LoginService,private router:Router) { }


  errorMsg;

  login(uname,pass){

   

    if((uname.toLowerCase() == 'thiru' || uname.toLowerCase() == 'aiuser') && pass.toLowerCase() == 'ai2023'){
      this.errorMsg=''

      // this.lservice.loggedIn=true
      this.router.navigate(['/course'])

      

    }

    else{
      this.errorMsg="You are not authenticated to visit this portal"
      // this.lservice.loggedIn=false
    }

  }

}
