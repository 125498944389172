exports.record=function(chat='nochat'){

       var ch=''
        var speech = true;
          window.SpeechRecognition = window.SpeechRecognition
                          || window.webkitSpeechRecognition;
  
          const recognition = new SpeechRecognition();
          recognition.interimResults = true;
          // const words = document.querySelector('.words');
          // words.appendChild(p);
  
          recognition.addEventListener('result', e => {
              const transcript = Array.from(e.results)
                  .map(result => result[0])
                  .map(result => result.transcript)
                  .join('')
  if(chat=='chat'){
    console.log(transcript);
    document.getElementById("inp").value = transcript;
    ch= transcript;
  }else{
    document.getElementById("p").value = transcript;
    console.log(transcript);
  }
              
          });
          
          if (speech == true) {
              recognition.start();
              // recognition.addEventListener('end', recognition.start);
          }
        console.log(ch);
    if(chat=='chat'){
        return ch;
    }
    
}