import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { KeyCloakService } from '../keycloak.service';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  constructor(public fb: FormBuilder, public ks: KeyCloakService, public ls: LoginService, public router: Router) {
    if (!(ls && ls.userObj && ls.userObj.admin)) {
      this.router.navigate(["/course"])
    }
  }

  ngOnInit(): void {
  }

  userForm: FormGroup = this.fb.group({
    user: ["", Validators.required],
    role: ["user", Validators.required]
  })

  message: string = ""

  addUser() {
    this.message = ""
    if (this.userForm.valid) {
      this.ks.addUser(this.userForm.value).subscribe((response: any) => {
        this.message = response.message
        this.userForm.reset();
      })
    }
  }

}
