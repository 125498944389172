import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { record } from '../record.js'
import { speech } from '../speech.js'


class wrapper {
  message: any
}

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {



  ngOnInit(): void {
  }

  lang: string = "English"

  courseError: string = ""

  answer: any;
  interviewSel: boolean = false
  interviewDes: any;
  mcqFlag: boolean = false;
  topicSel: boolean = false;
  mcqSel: boolean = false;
  content: any = []
  description: any = []
  topicDes: any;
  flag: boolean = false;
  selectedTopic: any;
  mcqsDes: any;
  level: any = "five"
  errorMsg: string = ""
  exerciseDes: any;
  exerciseSel: boolean = false
  explainDes: any = ""


  optionsFlag = false;
  lib: any;

  apiUrl = "https://ai.onwingspan.com/nodeapi/3001/course/"
  quesUrl="https://ai.onwingspan.com/nodeapi/3001/mcq/"

    //apiUrl = "http://localhost:3001/course/"
    //quesUrl = "http://localhost:3001/mcq/"



  constructor(private http: HttpClient,private cdr: ChangeDetectorRef) {


  }
  editorOptions = {
    language: 'typescript', minimap: { enabled: false },
    contextmenu: false,
    wordBasedSuggestions: false,
    acceptSuggestionOnEnter: "off",

    occurencesHighlight: false,

    wordWrap: "on",

  };
  // code: string= 'function x() {\nconsole.log("Hello world!");\n}';
  courseToc(library: any) {


    this.optionsFlag = false
    this.topicSel = false
    this.interviewSel = false
    this.mcqSel = false
    this.courseError = ""

    // this.selectedTopic=false



    this.lib = library;

    this.flag = true;
    this.description = [];


    //    let params = new HttpParams({ fromObject: { library: library } });
    //   let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }), };
    this.http.post<wrapper>(this.apiUrl + "ai/course", { library: library })
      .subscribe(data => {
        console.log("returned", data);



        let arr = data.message.split(/Module [0-9a-zA-Z]+:/i)
        console.log('split data', arr)

        for (let index = 0; index < arr.length; index++) {

          let c = arr[index].split(/\n/)

          if (c[0]) {
            this.courseError = ""
            this.description.push(c)

          }

        }
        console.log("dta is", this.description)

        for (let index = 0; index < this.description.length; index++) {

          if (this.description[index].length <= 1) {
            this.courseError = "Unexpected Response Format,Please generate again!"
            this.description = []
          }

          this.description[index][0] = this.description[index][0].replace(/[^0-9a-zA-Z ]/g, "")


        }



        this.content = data
        this.optionsFlag = true


      })

    setTimeout(() => {

  

      if (this.description.length == 0) {
        this.courseError = "Hold On! Your Request is taking more time , I am Trying to get Response!"
      }



    }, 40000);

  }

  topic(topic: any) {


    this.selectedTopic = false
    this.errorMsg = ""
    this.exerciseDes = ""

    this.interviewSel = false

    this.mcqSel = false


    this.mcqsDes = ""
    this.topicDes = ""
    this.interviewDes = ""

    let source = new EventSource(`${this.quesUrl}addTopic?library=${this.lib}&level=${this.level}&language=${this.lang}&topic=${topic.replace(/[^a-zA-Z0-9 ]/g, "")}`);
    source.onmessage = (event)=> {

    

      if (event.data == '[DONE]') {
     
        source.close()
  this.cdr.detectChanges()
   
      } else {
       

        this.topicDes += event.data;
        this.errorMsg = ""
        this.topicSel = true
        this.mcqSel = false
        this.interviewSel = false
        this.exerciseSel=false
        this.selectedTopic = topic
      
        this.cdr.detectChanges();
       


       
        
      }
      
    };


    // this.http.post<wrapper>(this.apiUrl + "ai/topic", {library:this.lib,topic:topic.replace(/[^a-zA-Z0-9 ]/g, ""), level: this.level , language:this.lang})
    //   .subscribe(data => {

    //     this.topicDes = data
    //     this.errorMsg = ""
    //     this.topicSel = true
    //     this.mcqSel = false
    //     this.interviewSel = false
    //     this.exerciseSel=false


    //     this.selectedTopic = topic




    //   })


    setTimeout(() => {

      console.log('topic timeout')
      if (this.topicDes == "" && this.mcqsDes == "" && this.interviewDes == "" && this.exerciseDes == "") {

        this.errorMsg = "Hold On! Your Request is taking more time , I am Trying to get Response!"
      }



    }, 90000);
  }

  retry() {


    this.topicDes = ""
    this.errorMsg = ""
    this.exerciseDes = ""
    this.mcqsDes = ""
    this.mcqSel = false
    this.topicSel = false
    this.interviewSel = false

    this.interviewDes = ""
    this.topicSel=false
    this.cdr.detectChanges()


    let lv = "layman"



    if (this.level == "Intermediate") {
      lv = "moderate learner"

    }

    if (this.level == "Advanced") {
      lv = "expert"
    }






    //let params = new HttpParams({ fromObject: { library: this.lib, topic: this.selectedTopic.replace(/[^a-zA-Z0-9 ]/g, ""), level: lv , language:this.lang} });
    //let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }), };
    this.http.post<wrapper>(this.apiUrl + "ai/topic", { library: this.lib, topic: this.selectedTopic.replace(/[^a-zA-Z0-9 ]/g, ""), level: lv, language: this.lang })
      .subscribe(data => {

        this.topicDes = data.message
        this.topicSel = true
        this.exerciseSel = false
        this.mcqSel = false
        this.interviewSel = false
        this.errorMsg = ""
        this.cdr.detectChanges()


      })



    setTimeout(() => {

      console.log('retry timeout')
      if (this.topicDes == "" && this.mcqsDes == "" && this.interviewDes == "") {

        this.errorMsg = "Hold On! Your Request is taking more time , I am Trying to get Response!"
      }



    }, 90000);






  }

  mcqs(arg?: any) {

    
    
    this.errorMsg = ""
    this.interviewSel = false
this.topicSel=false

    this.mcqSel = false
    this.interviewDes = ""
    this.topicDes = ""
    this.mcqsDes = ""
    this.exerciseDes = ""
    this.cdr.detectChanges()
    let lv = "simple"



    // if (this.level == "Intermediate") {
    //   lv = "medium"

    // }

    // if (this.level == "Advanced") {
    //   lv = "high"
    // }






    // let query = `Give me one Multiple Choice Question in json format on ${this.selectedTopic.replace(/[^a-zA-Z0-9 ]/g, "")} Concept in ${this.lib}.
    // The question needs to have 4 options.Translate the question and answers to ${this.lang}.
    // Please include questions of ${lv} complexity and indicate the complexity level in the json.`



    // if(this.lang == 'English'){

    //    query = `Give me one Multiple Choice Question in json format on ${this.selectedTopic.replace(/[^a-zA-Z0-9 ]/g, "")} Concept in ${this.lib}.
    // The question needs to have 4 options.
    // Please include questions of ${lv} complexity and indicate the complexity level in the json.`


    // }







    this.http.post<wrapper>(this.quesUrl + "questions", { language: this.lang, selectedTopic: this.selectedTopic.replace(/[^a-zA-Z0-9 ]/g, ""), library: this.lib, level: lv })
      .subscribe(data => {

        try {
     

          this.mcqsDes = JSON.parse(data.message[0].text.trim())
          this.errorMsg = ""
         
          this.topicSel = false
          this.interviewSel = false
          this.exerciseSel = false
          this.mcqSel = true
          this.cdr.detectChanges()
        }

        catch (error) {

          this.errorMsg = "Unexpected Response Format,Rendering in textual format"
          setTimeout(() => {
            this.topicDes = data.message[0].text.replace(/[{}",]/g, '')
            console.log("in timeout", this.topicDes)
            this.topicSel = true
            this.mcqSel = false
            this.exerciseSel = false
            this.interviewSel = false
            this.errorMsg = ""

          }, 1000);
        }


      })


    setTimeout(() => {

      console.log("in mcq timeout", typeof this.mcqsDes, this.topicDes)
      if ((this.mcqsDes == "" || typeof this.mcqsDes !== 'object') && this.topicDes == "" && this.interviewDes == "" && this.exerciseDes == "") {
 
        this.errorMsg = "Hold On! Your Request is taking more time , I am Trying to get Response!"
      }


    }, 90000);



  }


  interview() {
    this.mcqSel = false
    this.errorMsg = ""
    this.exerciseDes = ""
    this.interviewSel = false
    this.topicDes = ""
    this.mcqsDes = ""
    this.interviewDes = ""
    
    let lv = "simple"
this.topicSel = false
this.cdr.detectChanges();
    if (this.level == "Intermediate") {
      lv = "medium"
    }
    if (this.level == "Advanced") {
      lv = "high"
    }
    // let query = `Give me one interview question in json format on ${this.selectedTopic.replace(/[^a-zA-Z0-9 ]/g, "")} in ${this.lib} with their answers.Please include questions of ${lv} complexity in the json.
    // Translate the question and answers to ${this.lang}`

    this.http.post<wrapper>(this.quesUrl + "interview", { language: this.lang, selectedTopic: this.selectedTopic.replace(/[^a-zA-Z0-9 ]/g, ""), library: this.lib, level: lv })
      .subscribe(data => {
        try {
          this.interviewDes = JSON.parse(data.message[0].text.replace(/'/g, ''))
          this.topicSel = false
          this.mcqSel = false
          this.exerciseSel = false
          this.interviewSel = true
          this.errorMsg = ""
          this.cdr.detectChanges()
        }

        catch (error) {
          console.log("in error")
          this.errorMsg = "Unexpected Response Format,Rendering in textual format"
          setTimeout(() => {
            this.topicDes = data.message[0].text.replace(/[{}",]/g, '')
          
            this.topicSel = true
            this.exerciseSel = false
            this.mcqSel = false
            this.interviewSel = false
            this.errorMsg = ""

          }, 1000);
        }
      })


    setTimeout(() => {

      console.log("in interview timeout", typeof this.interviewDes, typeof this.topicDes)
      if ((this.interviewDes == "" || typeof this.interviewDes !== 'object') && this.topicDes == "" && this.mcqsDes == "" && this.exerciseDes == "") {

        this.errorMsg = "Hold On! Your Request is taking more time , I am Trying to get Response!"
      }




    }, 90000);



  }


  exercise() {

    this.lang = "English"

    this.exerciseDes = ""

    this.mcqSel = false
    this.errorMsg = ""
    this.interviewSel = false
    this.topicDes = ""
    this.mcqsDes = ""
    this.interviewDes = ""
    this.explainDes = ""
    this.cdr.detectChanges()

    //
    //    let params = new HttpParams({ fromObject: { library: this.lib, topic: this.selectedTopic.replace(/[^a-zA-Z0-9 ]/g, "") } });
    //   let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }), };
    this.http.post<wrapper>(this.apiUrl + "ai/exercise", { library: this.lib, topic: this.selectedTopic.replace(/[^a-zA-Z0-9 ]/g, "") })
      .subscribe(data => {

        this.exerciseDes = data

        this.explainDes = ""

        //this.topicDes = data
        this.errorMsg = ""
        this.exerciseSel = true
        this.topicSel = false
        this.mcqSel = false
        this.interviewSel = false
        this.cdr.detectChanges()




      })


    setTimeout(() => {

      console.log('exercise timeout')
      if (this.topicDes == "" && this.interviewDes == "" && this.mcqsDes == "" && this.exerciseDes == "") {

        this.errorMsg = "Hold On! Your Request is taking more time , I am Trying to get Response!"
      }



    }, 90000);





  }

  change_level(arg?: any) {


    this.level = arg;
    if (this.lib) {
      this.retry()

    }



  }

  explain() {

    if (this.explainDes == "") {
      //
      //     let params = new HttpParams({ fromObject: { library: this.lib, code: this.exerciseDes.message} });
      //  let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }), };
      this.http.post<wrapper>(this.apiUrl + "ai/explain", { library: this.lib, code: this.exerciseDes.message })
        .subscribe(data => {
          this.explainDes = data
this.cdr.detectChanges();
        })

    }


  }

  recorder() {
    record()
  }

  change_lang(arg: string) {
    this.lang = arg
    this.retry()
  }

  text_speech(arg: any) {

    speech(arg, this.lang)

  }


}

